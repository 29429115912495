'use client'

import Image from 'next/image'
import Link from 'next/link'

import { PopoverClose } from '@radix-ui/react-popover'
import clsx from 'clsx'
import { BsChevronLeft } from 'react-icons/bs'
import { PiShareNetworkLight } from 'react-icons/pi'
import { SiDiscord, SiGitbook, SiGithub, SiNotion, SiTelegram, SiX } from 'react-icons/si'

import { DISCORD_LINK } from '../constants/socials'

import { Popover, PopoverContent, PopoverTrigger } from './Popover'

interface SocialsProps {
  className?: string
  isOpen?: boolean
  setIsOpen?: (isOpen: boolean) => void
}

export const SocialsButton = ({ className, isOpen, setIsOpen }: SocialsProps) => {
  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger>
        <div className="bg-gradient-to-r from-cblue-400/20 via-black to-cblue-400/20 p-[1px] rounded bg-transparent h-10">
          <div className="black-gradient rounded flex items-center p-[9px] gap-1.5 text-sm relative z-10">
            <PiShareNetworkLight className="h-4 w-auto" />
            Socials
            <BsChevronLeft size={8} />
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent
        className={clsx(
          'h-10 !rounded flex w-full data-[state=open]:data-[side=left]:duration-300 data-[state=closed]:data-[side=left]:duration-300',
          'bg-gradient-to-r from-cblue-400/20 via-black to-cblue-400/20 p-[1px] backdrop-filter-[4.5px] glow-box',
          className,
        )}
        sideOffset={-103}
        side="left"
      >
        <div className="black-gradient rounded-sm flex flex-row gap-4 justify-center items-center p-[9px]">
          <Link href={DISCORD_LINK} target="_blank">
            <SiDiscord className="text-white h-5 w-5 hover:opacity-80 transition-opacity" width={0} height={0} />
          </Link>
          <Link href="https://twitter.com/ethena_labs" target="_blank">
            <SiX className="text-white h-5 w-5 hover:opacity-80 transition-opacity" width={0} height={0} />
          </Link>
          <Link href="https://t.me/ethena_labs" target="_blank">
            <SiTelegram className="text-white h-5 w-5 hover:opacity-80 transition-opacity" width={0} height={0} />
          </Link>
          <Link href="https://ethena-labs.gitbook.io/ethena-labs/" target="_blank">
            <SiGitbook className="text-white h-5 w-5 hover:opacity-80 transition-opacity" width={0} height={0} />
          </Link>
          <Link href="https://github.com/ethena-labs" target="_blank">
            <SiGithub className="text-white h-5 w-5 hover:opacity-80 transition-opacity" width={0} height={0} />
          </Link>
          <Link href="https://ethena.notion.site/Ethena-FAQs-3ccc1437e13343f8b74c0d005e4f5128" target="_blank">
            <SiNotion className="text-white h-5 w-5 hover:opacity-80 transition-opacity" width={0} height={0} />
          </Link>
          <Link href="https://mirror.xyz/0xF99d0E4E3435cc9C9868D1C6274DfaB3e2721341" target="_blank">
            <Image
              src="/shared/mirror-alt.svg"
              width={0}
              height={0}
              alt="mirror"
              className="h-5 w-5 hover:opacity-80 transition-opacity"
            />
          </Link>
          <PopoverClose asChild>
            <Image
              src="/shared/close-icon.svg"
              alt="Close"
              width={0}
              height={0}
              className="h-3.5 w-auto hover:opacity-80 transition-opacity cursor-pointer"
            />
          </PopoverClose>
        </div>
      </PopoverContent>
    </Popover>
  )
}
