'use client'

import useSWR from 'swr'

import { useFormatYieldTooltip } from '../hooks/useFormatYieldTooltip'
import { FetchError } from '../types'
import { YieldResponse } from '../utils/edge/protocol-and-staking-yield'

export const useAvgSusdeYieldTooltipData = () => {
  const { data: yieldData } = useSWR<YieldResponse, FetchError>('/api/yields/protocol-and-staking-yield')

  const [yieldStr, tooltip] = useFormatYieldTooltip({
    baseTooltip:
      'Estimated 7-day trailing sUSDe APY as of the prior day based on certain assumptions. This estimate is for informational purposes only and does not reflect actual weekly sUSDe APY results, which are calculated, published, and transferred once per week on Thursday, covering Wednesday to Wednesday periods. Actual results may vary.',
    yieldData: yieldData?.avgStakingYield ?? { lastUpdated: '', value: undefined },
  })

  return { tooltip, yieldStr }
}
